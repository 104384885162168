// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bK_mb";
export var caseContactFormSection = "bK_kr";
export var caseSolutionRing = "bK_mc";
export var caseStudyBackground__bgColor = "bK_kn";
export var caseStudyBackground__lineColor = "bK_kp";
export var caseStudyCta__bgColor = "bK_kG";
export var caseStudyHead = "bK_kj";
export var caseStudyHead__imageWrapper = "bK_kk";
export var caseStudyProjectsSection = "bK_ks";
export var caseStudyQuote__dark = "bK_l7";
export var caseStudyQuote__light = "bK_l8";
export var caseStudySolution__ringOne = "bK_l9";
export var caseStudySolution__ringTwo = "bK_kz";
export var caseStudyTechSection = "bK_kq";
export var caseStudyVideo__ring = "bK_kv";
export var caseStudy__bgDark = "bK_kh";
export var caseStudy__bgLight = "bK_kg";